import { useStaticQuery, graphql, Link } from 'gatsby';
import React from 'react';
import { kebabCase } from 'lodash';
import { GatsbyImage } from 'gatsby-plugin-image';

const LatestArticles = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }, limit: 5) {
                edges {
                    node {
                        title
                        slug
                        category
                        publishDate(formatString: "MMMM Do, YYYY")
                        author {
                            name
                        }
                        heroImage {
                            gatsbyImageData(layout: FIXED, width: 80)
                        }
                    }
                }
            }
        }
    `);
    const { allContentfulBlogPost: { edges } } = data;
    return (
        <section>
            <h2 className="latest-heading pb-5 m-0">
                <span>الكتابات الأخيرة</span>
            </h2>
            {edges.map(({ node }) => {
                return (
                    <Link
                        key={node.slug}
                        to={'/articles/' + node.category + '/' + kebabCase(node.slug)}
                        className="link latest-articles"
                        data-sal="slide-up"
                        data-sal-delay="150"
                        data-sal-easing="ease-in">
                        <article className="d-flex align-items-center">
                            <GatsbyImage
                                image={node.heroImage.gatsbyImageData}
                                className="rounded"
                                style={{ maxHeight: 80 }}
                                alt={node.title}
                            />
                            <div className="info p-2">
                                <div className="text-center">
                                    <h5>{node.title}</h5>
                                    <p className="date">
                                        <span>
                                            by {node.author.name}
                                            {` / `}
                                        </span>
                                        <span>{node.publishDate}</span>
                                    </p>
                                </div>
                            </div>
                        </article>
                    </Link>
                );
            })}
        </section>
    );
};
export default LatestArticles;
