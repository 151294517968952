import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { FaFacebookSquare } from 'react-icons/fa';
import LatestArticles from '../components/latest-articles';
import Layout from '../components/layout';
import Seo from '../components/seo';

const Author = ({
    data: {
        owner: {
            title,
            experience,
            facebook,
            publications,
            qualifications,
            name,
            shortBio,
            image,
            placeholderImage,
            who,
        },
        assistant,
    },
}) => {
    return (
        <Layout>
            <Seo
                title={`${name + ' ' + title}`}
                description={shortBio.shortBio}
                keywords={[ 'الصحه العامه', 'الصحه النفسيه', 'شغبطه بقلم د.هبه اللكاوي', 'shaghbatta' ]}
                image={placeholderImage.fixed}
            />
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-8">
                        <div className="container-white my-3 p-3">
                            <div className="author-brief mb-4">
                                <h1>
                                    {name} <span className="badge main-badge">{who}</span>
                                </h1>

                                <p>{title}</p>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: shortBio.childMarkdownRemark.html,
                                    }}
                                />
                            </div>
                            <div className="about-grid">
                                <div className="image-social-container">
                                    <div
                                        className="text-center team mx-auto"
                                        style={{
                                            width: '70%',
                                        }}>
                                        <GatsbyImage image={image.gatsbyImageData} className="rounded img-fluid" />
                                    </div>
                                    <ul className="social">
                                        <li>
                                            <a href={facebook} target="_blank" rel="noopener noreferrer">
                                                <FaFacebookSquare />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="list-container my-3">
                                    <h3 className="m-3">المؤهلات</h3>
                                    <ul data-sal="slide-in" data-sal-delay="150" data-sal-easing="ease-in">
                                        {qualifications.map((i) => <li key={i}>{i}.</li>)}
                                    </ul>
                                    <h3 className="m-3">الخبرات </h3>
                                    <ul data-sal="slide-in" data-sal-delay="150" data-sal-easing="ease-in">
                                        {experience.map((i) => <li key={i}>{i}.</li>)}
                                    </ul>
                                    <h3 className="m-3">الإصدارات الكتابية </h3>
                                    <ul data-sal="slide-in" data-sal-delay="150" data-sal-easing="ease-in">
                                        {publications.map((i) => <li key={i}>{i}.</li>)}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="container">
                            <div className="container-white my-3 row align-items-center">
                                <section className="col-md-8 my-4 team-container">
                                    <div className="author-brief">
                                        <h2>
                                            {assistant.name} <span className="badge main-badge">{assistant.who}</span>
                                        </h2>

                                        <p>{assistant.title}</p>
                                    </div>
                                </section>
                                <section className="col-md-4 my-3">
                                    <div className="text-center assistant m-auto">
                                        <GatsbyImage
                                            image={assistant.image.gatsbyImageData}
                                            className="rounded img-fluid"
                                        />
                                    </div>
                                </section>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-md-4 my-3">
                        <LatestArticles />
                        {/* <InstagramPage link={'https://www.instagram.com/p/CByMxrRgiKx/'} title={` شغبطه`} /> */}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default Author;

export const pageQuery = graphql`
    query AboutQuery {
        owner: contentfulPerson(contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" }) {
            email
            experience
            facebook
            publications
            qualifications
            phone
            name
            who
            shortBio {
                childMarkdownRemark {
                    html
                }
            }
            title
            image {
                gatsbyImageData(layout: FULL_WIDTH)
            }
            placeholderImage: image {
                gatsbyImageData(layout: FIXED)
            }
        }
        assistant: contentfulPerson(contentful_id: { eq: "1eLZ4uDqNMLJGRTQqfgDAG" }) {
            title
            name
            who
            image {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
    }
`;
